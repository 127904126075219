import axios from 'axios';
import storage from '../common/storage';

const abortBearer = {
  aborted: false,
  ctrlList: [],
  abort() {
    this.aborted = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const c of this.ctrlList) {
      c.abort();
    }
    this.ctrlList = [];
  },
  reset() {
    this.aborted = false;
  },
  newAbortController() {
    const ctrl = new AbortController();
    this.ctrlList.push(ctrl);
    return ctrl;
  },
  removeController(ctrl) {
    this.ctrlList = this.arrayRemove(this.ctrlList, ctrl);
  },
  arrayRemove(arr, value) {
    return arr.filter((ele) => ele !== value);
  },
};

const shouldIntercept = (error) => {
  try {
    return error.response.data.code === 'error.auth.0007';
  } catch (e) {
    return false;
  }
};

const attachTokenToRequest = (request, token) => {
  request.headers.Authorization = `Bearer ${token}`;
};
// const attachRefreshTokenToRequest = (request, refreshToken) => {
//   request.headers.Refresh = `${refreshToken}`;
// };

function addInterceptor(instance, auth) {
  instance.interceptors.request.use(
    (config) => {
      // const token = storage.getToken().accessToken;
      // if (token === 'UnAuthorized' || token === null || token === undefined || token === 'undefined') {
      //   storage.setToken(token);
      //   let baseURL = process.env.VUE_APP_BASE_URL;
      //   if (baseURL === undefined) {
      //     baseURL = 'localhost:8080/';
      //   }
      //   window.location.href = baseURL;
      // }
      if (auth === true) {
        attachTokenToRequest(config, storage.getToken().accessToken);
        // attachRefreshTokenToRequest(config, storage.getRefreshToken());
      }
      if (config.method === 'get' || config.method === 'post' || config.method === 'put') {
        config.timeout = 30000;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use((response) => response,
    (error) => {
      console.log(error);
      if (!shouldIntercept(error) || error.config._retry || error.config._queued) {
        if (Object.prototype.hasOwnProperty.call(error.config, 'errorHandle') && !error.config.errorHandle) {
          return Promise.reject(error);
        }
        // this.$EventBus.$emit('response:error', error);
        // 인증 실패면 refresh할건지 팝업 띄운다
        if (error.response?.status === 401) {
          console.log(error.response);
          // storage.setToken(error.response.data.token);
          // alert('인증 정보가 올바르지 않습니다. 다시 로그인 해주십시오.');
          storage.removeToken();
          window.location.href = process.env.VUE_APP_BASE_URL;
          // let baseURL = process.env.VUE_APP_BASE_URL;
          // window.location.href = `${baseURL}/`;
        } else if (error.code === 'ECONNABORTED') {
          // CORS 가 처리된 외부 요청인데 타임아웃이 발생된 경우
          // 로그인 화면으로 이동하지 않도록 한다.
          alert('요청이 만료되었습니다.');
          if (!error.config.withCredentials) {
            window.location.href = process.env.VUE_APP_BASE_URL;
          }
          // 400 - BAD_REQUEST(명시적 에러), 402 - BLOCKCHAIN ERROR, 500 - INTERNAL_SERVER_ERROR
          // eslint-disable-next-line max-len
        } else if (error.response?.status === 400 || error.response?.status === 402 || error.response?.status === 412 || error.response?.status === 500) {
          console.error(`ERROR: ${error.response?.status}`);
          const statusCode = error.response?.status;
          const errorMessage = error.response.data.message;
          if (statusCode === 500 && errorMessage === '토큰 추가 지급을 실패 했습니다.') {
            const newMessage = `${errorMessage}\n재 시도 하시겠습니까?`;
            if (window.confirm(newMessage)) {
              window.location.href = process.env.VUE_APP_BASE_URL;
            } else {
              window.location.href = '/web/errorpage';
            }
          } else {
            alert(error.response.data.message);

            window.location.href = process.env.VUE_APP_BASE_URL;
          }

          console.log(error.response.status);
        } else {
          console.log(error.response.status);
        }
        return Promise.reject(error);
      }
      return Promise.reject(error);
      // const { config: originalRequest } = error;
      // return instance.request(originalRequest);
    },
  );
  return instance;
}

// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
const api = addInterceptor(axios.create(), true);
const http = addInterceptor(axios.create({
  withCredentials: true,
}), false);
export { api, http, abortBearer };
