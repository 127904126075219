<template>
  <v-app>
    <v-card flat width="100%" height="100%" color="#e6e6f1" class="lot_login">
      <v-card flat width="480" class="login-wrap">
        <v-card-title class="pa-0 text-center">
          <h1>
            블록체인 기반<br>
            <strong>사내 추첨 시스템</strong>
          </h1>
          <div class="pt-4 d-block h-desc">
            <v-btn
              outlined rounded
              color="primary"
              @click="goMain"
            >메인으로 가기 </v-btn>
          </div>
        </v-card-title>
      </v-card>
      <div class="bg-area">
        <div class="symbol_01">
          <v-img src="@/assets/images/ess_bg01.svg" alt=""></v-img>
        </div>
        <div class="symbol_02">
          <v-img src="@/assets/images/ess_bg02.svg" alt=""></v-img>
        </div>
      </div>

    </v-card>

    <dialog-lot-list v-model="openLotList" :key="tabBoolean ? 0 : 2" @openModal="openModal"></dialog-lot-list>
    <dialog-lot-dtl v-model="openLotDtl" :editedItem="editedItem" @openModal="openModal"></dialog-lot-dtl>
    <dialog-lot-apply v-model="openLotApply" :editedItem="editedItem" @openModal="openModal"></dialog-lot-apply>
    <dialog-lot-complete v-model="openLotComplete" :editedItem="editedItem" @openModal="openModal"></dialog-lot-complete>
    <dialog-lot-cancel v-model="openLotCancel" :editedItem="editedItem" @openModal="openModal"></dialog-lot-cancel>
    <dialog-lot-history v-model="openLotHistory" :editedItem="editedItem" @openModal="openModal"></dialog-lot-history>
    <dialog-lot-winner-list v-model="openLotWinnerList" :editedItem="editedItem" @openModal="openModal"></dialog-lot-winner-list>

  </v-app>
</template>

<script>
import DialogLotList from '@/views/dialog/DialogLotList.vue';
import DialogLotDtl from '@/views/dialog/DialogLotDtl.vue';
import DialogLotApply from '@/views/dialog/DialogLotApply.vue';
import DialogLotComplete from '@/views/dialog/DialogLotComplete.vue';
import DialogLotCancel from '@/views/dialog/DialogLotCancel.vue';
import DialogLotHistory from '@/views/dialog/DialogLotHistory.vue';
import DialogLotWinnerList from '@/views/dialog/DialogLotWinnerList.vue';
// import storage from '../common/storage';

export default {
  name: 'SloAuth',
  props: {
    eventId: {
      type: String,
    },
  },
  components: {
    // 사용자
    DialogLotList, // 이벤트 리스트
    DialogLotDtl, // 카테고리 별 이벤트 상세
    DialogLotApply, // 응모하기
    DialogLotComplete, // 응모완료
    DialogLotCancel, // 응모취소
    DialogLotHistory, // 응모이력
    DialogLotWinnerList, // 당첨자 리스트
  },
  data() {
    return {
      // 공사중 다어얼로그 화면에 출력 유/무
      isConstructing: false,
      loading: false,
      openLotList: false,
      openLotDtl: false,
      openLotApply: false,
      openLotComplete: false,
      openLotCancel: false,
      openLotHistory: false,
      openLotWinnerList: false,

      editedItem: {},
      // tabId: 0,
      tabBoolean: true,
    };
  },
  created() {
    // const userid = storage.getToken().accessToken;
    const userid = localStorage.getItem('userId');
    if (userid === null || userid === undefined || userid === '') {
      alert('사용자 정보가 없습니다, 클레버스 로그인 후 이용하세요');
      return;
    }
    // if (userid == null || userid === '') {
    //   alert('사용자 정보가 없습니다, 로그인 후 이용하세요');
    //   return;
    // }
    if (this.isConstructing) {
      this.$dialog.info({
        text: `<span class="f14">안녕하세요. <br>
            새로운 <블록체인 기반 추첨 서비스>를 선보이기 위해 잠시 작업을 진행 중입니다.<br>
            작업 일정 : 2022/12/08 ~ 09 (2일간)
            </span>`,
        title: ' 공지사항 ',
        width: '600',
      });
      localStorage.setItem('tab', '0');
    } else {
      this.goMain();
    }
    localStorage.setItem('detailTab', '0');
  },
  methods: {
    go(path) {
      console.log(this.$route.path);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    goMain() {
      // this.$axios.post('/auth/slo', { slo_p_ota: this.slo_p_ota }).then((res) => {
      if (!this.isConstructing) {
        this.openModal('openLotList');
      }
    },
    submit() {
    },
    openModal(name, item) {
      const userid = localStorage.getItem('userId');
      if (userid === null || userid === undefined || userid === '') {
        alert('사용자 정보가 없습니다, 클레버스 로그인 후 이용하세요');
        return;
      }
      console.log('name:', name, item);
      this.$data[name] = true;
      this.loading = true;

      localStorage.setItem('tab', '0');
      if (item !== undefined && item !== 'winnerList' && item !== 'List') {
        const obj = {};
        this.editedItem = Object.assign(obj, item);
        this.editedItem = item;
      } else if (item === 'winnerList') {
        this.tabBoolean = !this.tabBoolean;
        localStorage.setItem('tab', '2');
        localStorage.setItem('detailTab', '2');
      } else if (item === 'List') {
        this.tabBoolean = !this.tabBoolean;
        localStorage.setItem('tab', '0');
      }
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.loading = false), 1500);
    },
  },
  windowClose() {
    window.close();
  },
};
</script>
