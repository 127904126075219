<template>
<!-- eslint-disable-next-line max-len -->
<v-dialog v-model="show" width="1200" maxWidth="1200" content-class="dialog-lot dialogLotDtl" :loading="loading">
  <v-card>
    <v-card-title>
      <!-- title -->
      <slot name="title">
        <div class="btn-area d-block">
          <!-- 뒤로 button -->
          <v-btn outlined rounded
            color="#fff"
            @click="opener('openLotList')"
            class="px-4"
          >
            <v-icon class="mr-1 white--text f12"> icon-hw_fb43 </v-icon>
            목록
          </v-btn>
          <!-- 나의 응모 현황 button: 1. 블록체인 기반 사내추첨 리스트의 응모한 추첨 탭으로 이동 -->
          <v-btn outlined rounded
            color="#fff"
            @click="opener('openLotList', 'winnerList')"
            class="ml-2 px-4"
          >
            <v-icon class="mr-1 f12"> icon-hw_fb72 </v-icon>
            나의 응모 현황
          </v-btn>
        </div>
        <div class="mt-6 d-flex align-items-center justify-space-between">
          <strong class="lot-title pt-2 ellipsis">
            {{editedItem.eventNm === undefined ? dummy.eventNm : editedItem.eventNm}}
          </strong>
          <!-- <div class="lot-dates pl-10 f18">응모기간 : 22.04.16 ~ 22.05.15</div> -->
        </div>
      </slot>

      <v-spacer></v-spacer>

    </v-card-title>
    <v-card-text>
      <!-- content -->
      <slot name="content">
        <v-card flat width="542" class="h-lot-dtl ma-auto">
          <v-card-title class="pt-15 justify-center text-center">
            <div>
            <strong class="f26">
              <em>{{empNm}}님</em><br>
              응모를 취소하시겠습니까?<br/>
            </strong>
            </div>
            <span>
              <p class="pt-4 f16">
                <span class="red--text">응모 취소 가능 횟수 : {{countCancelApplicant}}</span> <br>
              </p>
              <p class="pt-4 f16">
                하단의 확인 버튼을 누르면 응모가 취소됩니다.
              </p>
            </span>
          </v-card-title>

          <v-card-text class="pa-0">
            <strong class="lot-dtl-title mb-3 mx-auto d-block">응모정보
              (현재 전체 응모 토큰 : {{editedItem.tokenSum === null ? '0' :  editedItem.tokenSum}})</strong>
            <v-simple-table
              class="lot-tbl-dtl rounded-0"
            >
              <template v-slot:default>
                <tbody>
                  <tr>
                    <th>이벤트 명</th>
                    <td>
                      <v-chip @click="detailInfo(item)"
                        small color="#edf0ff"
                        text-color="#10207d"
                        class="mr-1 font-weight-light">상세설명</v-chip>
                      <!-- <v-chip small color="#edf0ff" text-color="#10207d" class="mr-4 font-weight-light">추첨 1</v-chip> -->
                      <span class="font-weight-medium">
                        {{ editedItem.itemNm === undefined ? dummy.sttDate : editedItem.itemNm }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>응모 토큰 종류</th>
                    <td class="font-weight-medium">
                    <span class="font-weight-medium d-flex flex-row">
                      <span>
                        <v-img v-if="imageId.length > 0" :src="require(`@/assets/images/${imageId}`)" width="25" height="25" alt="토큰 아이콘" />
                        <v-img v-else src="@/assets/images/id_lottoken.png" width="25" height="25" alt="토큰 아이콘" />
                      </span>
                      <span class="ps-1" style="height:25px; display: grid; place-items: center;">
                        {{ editedItem.tokenName }}
                      </span>
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <th>응모 토큰 수</th>
                    <td class="font-weight-medium">
                    <span class="font-weight-medium">
                      {{ editedItem.applicantToken }}
                    </span>
                    </td>
                  </tr>
                  <tr v-if="editedItem.winnerNum <= 1">
                    <th>{{ empNm }}님의 당첨확률</th>
                    <td>
                       {{ probability }}%
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </slot>
    </v-card-text>
    <v-card-actions>
      <slot name="footer">
        <v-spacer></v-spacer>
        <v-btn outlined rounded
          color="#ccc"
          @click="close"
          class="px-4"
          :disabled="cancelDisabled !== false"
        >
          <v-icon class="mr-1 black--text f12"> icon-m-ic-close </v-icon>
          <p v-if="countCancelApplicant <= 0">닫기</p>
          <p v-else>취소</p>
        </v-btn>
        <v-btn depressed rounded
          color="primary"
          @click="saveAfterConfirm"
          class="px-4 white--text"
          :disabled="cancelDisabled !== false || countCancelApplicant <= 0"
        >
          <v-icon class="mr-1 white--text f12"> icon-hw-ic-check3 </v-icon>
          <p v-if="countCancelApplicant <= 0">더 이상 취소할 수 없습니다.</p>
          <p v-else>확인</p>
        </v-btn>
      </slot>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { api } from '@/axios';

export default {
  name: 'DialogLotCancel',
  components: {
  },
  props: {
    value: Boolean,
    loading: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    openModal: Function,
    editedItem: {
      type: Object,
      // default: () => [],
    },
  },
  data() {
    return {
      probability: 0,
      empNm: '',
      cancelDisabled: false,
      submitFlag: false,
      dummy: {
        eventNm: '거제 3차 르씨엘 예약 접수',
        evtDtlId: 'ed-09',
        applNum: '100',
        rmnToken: '3',
        stdToken: '200',
        winNum: '1',
        sttDate: '22.04.16',
        endDate: '22.05.15',
        applCd: '50',
      },
      countCancelApplicant: 0,
      imageId: '',
    };
  },
  computed: {
    show: {
      get() {
        // 방산에서는 사용자 명 대신 사번 사용
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.empNm = localStorage.getItem('userId');
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.countCancelApplicant = process.env.VUE_APP_COUNT_CANCEL - this.editedItem.countCancel;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        try {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.imageId = `${this.editedItem.tokenId.toLowerCase()}.png`;
        } catch (e) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.imageId = '';
          // console.error('ERROR NOT FOUND TOKEN IMAGE');
        }
        if (this.editedItem.applicantNum <= this.editedItem.winnerNum) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.probability = 100.00;
        } else if (this.editedItem.winnerNum === 1) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.probability = (((this.editedItem.applicantToken / this.editedItem.tokenSum)).toFixed(4) * 100).toFixed(2);
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.probability = (((this.editedItem.winnerNum)
             * (this.editedItem.applicantToken / this.editedItem.tokenSum)
             // eslint-disable-next-line no-restricted-properties
             * (Math.pow((1 - (this.editedItem.applicantToken / this.editedItem.tokenSum)),
               this.editedItem.winnerNum - 1))).toFixed(4) * 100).toFixed(2);
        }
        // eslint-disable-next-line consistent-return
        return this.value;
      },
      set() {
        this.$emit('input', false);
      },
    },
  },
  filters: {
  },
  mounted() {
  },
  watch: {
  },
  created() {
    this.$EventBus.$on('empNm', this.onReceiveName);
  },
  methods: {
    opener(name) {
      // 임시 : modal 내 페이지 전환 필요
      console.log('[BK] OPENER');
      this.show = false;
      this.$emit('openModal', name);
    },
    submitCheck() {
      if (this.submitFlag) {
        return this.submitFlag;
      // eslint-disable-next-line no-else-return
      } else {
        this.submitFlag = true;
        return false;
      }
    },
    saveAfterConfirm() {
      let message = '정말로 취소하시겠습니까?<br/>';
      if (this.countCancelApplicant === 1) {
        message += '이후 더 이상 취소할 수 없습니다.';
      } else {
        message += `남은 취소가능 횟수는 <b>${this.countCancelApplicant}회</b> 입니다.`;
      }
      this.$dialog.confirm({
        title: '취소하기',
        text: message,
        actions: {
          false: '닫기',
          true: {
            text: '취소',
            handle: () => {
              this.save();
            },
          },
        },
      });
    },
    save() {
      if (this.submitCheck()) {
        return;
      }

      if (this.countCancelApplicant <= 0) {
        console.error('[BK] 취소가능 회수', this.countCancelApplicant);
        return;
      }

      this.cancelDisabled = true;
      // this.$axios.post('/apply/cancel', { userId: localStorage.getItem('userId'), itemId: this.editedItem.itemId,
      api.put('/event/apply/cancel', {
        userId: localStorage.getItem('userId'),
        eventId: this.editedItem.eventId,
        itemId: this.editedItem.itemId,
      }).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.$EventBus.$emit('tokenChanged', 'doUpdate');
        }
        this.cancelDisabled = false;
        this.submitFlag = false;
        this.show = false;
        this.editedItem.currentPer = false;
        this.$emit('openModal', 'openLotDtl', this.editedItem);
      });
    },
    close() {
      this.show = false;
      this.$emit('openModal', 'openLotDtl', this.editedItem);
    },
    crfunction(detailInfo) {
      return detailInfo.split('\n').join('<br />');
    },
    detailInfo() {
      let info = '';
      if (this.editedItem.detailInfo === '') {
        info = '상세설명이 없습니다.';
      } else {
        info = this.crfunction(this.editedItem.detailInfo);
      }
      this.$dialog.info({
        text: info,
        title: ' 상세설명 ',
      });
    },
    onReceiveName(empNm) {
      this.empNm = empNm;
    },
    testImg() {
      return 'id_lottoken.png';
    },
  },
};
</script>
