<template>
<!-- eslint-disable-next-line max-len -->
<v-dialog v-model="show" width="1200" maxWidth="1200" content-class="dialog-lot dialogLotDtl" :loading="loading">
  <v-card>
    <v-card-title>
      <!-- title -->
      <slot name="title">
        <div class="btn-area d-block">
          <!-- 뒤로 button -->
          <v-btn outlined rounded
            color="#fff"
            @click="opener('openLotList')"
            class="px-4"
          >
            <v-icon class="mr-1 white--text f12"> icon-hw_fb43 </v-icon>
            목록
          </v-btn>
          <!-- 나의 응모 현황 button: 1. 블록체인 기반 사내추첨 리스트의 응모한 추첨 탭으로 이동 -->
          <v-btn outlined rounded
            color="#fff"
            @click="opener('openLotList', 'winnerList')"
            class="ml-2 px-4"
          >
            <v-icon class="mr-1 f12"> icon-hw_fb72 </v-icon>
            나의 응모 현황
          </v-btn>
        </div>
        <div class="mt-6 d-flex align-items-center justify-space-between">
          <strong class="lot-title pt-2 ellipsis">
            {{lists.eventNm === undefined ? dummy.eventNm : lists.eventNm}}
          </strong>
          <!-- <div class="lot-dates pl-10 f18">응모기간 : 22.04.16 ~ 22.05.15</div> -->
        </div>
      </slot>

      <v-spacer></v-spacer>

    </v-card-title>
    <v-card-text>
      <!-- content -->
      <slot name="content">
        <v-card flat width="542" class="h-lot-dtl ma-auto">
          <v-card-title class="pt-15 justify-center flex-column text-center">

            <v-icon color="primary" class="d-block f60"> icon-hw-circle-check02 </v-icon>

            <strong class="f26">
              <em>{{ empNm }}님</em><br>
              응모가 완료되었습니다.
            </strong>
            <p class="pt-3 f16">
              {{lists.eventNm === undefined ? dummy.eventNm : lists.eventNm}}
              -
              {{ editedItem.itemNm }}
              (응모토큰: {{ editedItem.applicantToken }}개)
            </p>
          </v-card-title>
        </v-card>
      </slot>
    </v-card-text>
    <v-card-actions>
      <slot name="footer">
        <v-spacer></v-spacer>
        <!-- <v-btn outlined rounded
          color="#ccc"
          @click="close"
          class="px-4"
        >
          <v-icon class="mr-1 black--text f12"> icon-m-ic-close </v-icon>
          취소
        </v-btn> -->
        <v-btn depressed rounded
          color="primary"
          @click="close"
          class="px-4 white--text"
        >
          <v-icon class="mr-1 white--text f12"> icon-hw-ic-check3 </v-icon>
          확인
        </v-btn>
      </slot>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { api } from '@/axios';

export default {
  name: 'DialogLotComplete',
  components: {
  },
  props: {
    value: Boolean,
    loading: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    openModal: Function,
    editedItem: {
      type: Object,
      // default: () => [],
    },
  },
  data() {
    return {
      empNm: '',
      lists: '',
      dummy: {
        eventNm: '거제 3차 르씨엘 예약 접수',
        evtDtlId: 'ed-09',
        applNum: '100',
        rmnToken: '3',
        stdToken: '200',
        winNum: '1',
        sttDate: '22.04.16',
        endDate: '22.05.15',
        applCd: '50',
      },

    };
  },
  computed: {
    show: {
      get() {
        this.fetchData();
        return this.value;
      },
      set() {
        this.$emit('input', false);
      },
    },
  },
  filters: {
  },
  mounted() {
  },
  watch: {
  },
  created() {
    // 방산에서는 사용자명 대신 사번을 사용하도록 함
    this.empNm = localStorage.getItem('userId');
  },
  methods: {
    async fetchData() {
      if (this.editedItem.eventId !== 'undefined' && this.editedItem.eventId !== undefined) {
        await api.get(`/event/get/${this.editedItem.eventId}`).then((res) => {
          this.lists = res.data;
        });
      }
    },
    opener(name) {
      // 임시 : modal 내 페이지 전환 필요
      this.show = false;
      this.$emit('openModal', name);
    },
    save() {
      this.show = false;
    },
    close() {
      this.show = false;
    },
  },
};
</script>
